"use strict"

export class map {
  #field
  #map
  #mapAux
  #poligonsSemInfo
  #poligonsComInfo
  #itemsOnMap
  constructor({ field, div, options, poligonsSemInfo, poligonsComInfo }) {
    this.#field = field
    if (div) { this.#map = new window.google.maps.Map(div, options) }
    this.#poligonsSemInfo = poligonsSemInfo
    this.#poligonsComInfo = poligonsComInfo
  }
  get field() { return this.#field }
  // add = (objects) => objects.forEach(object => object.setMap(this.#map))
  // addAll = () => this.#poligonsComInfo.setMap(this.#map)
  setMapxAux(mapAux) { this.#mapAux = mapAux }
  openMapAux({ mainTitle, auxTitle }) {
    this.#field.aux.show = true
    this.#field.aux.mainTitle = mainTitle
    this.#field.aux.auxTitle = auxTitle
  }
  closeMapAux() {
    this.#field.aux.show = false
    this.#field.aux.mainTitle = ''
    this.#field.aux.auxTitle = ''
  }
  #getObjectType = ({ type, coords }) => {
    if (type) { return type }
    return coords?.length > 1 ? 'Polygon' : 'Marker'
  }
  insertObject = ({ type, coords, coordsWithHoles, options, infoRight, eventsRight, info, events, data }) => {
    console.log({ infoRight, eventsRight })
    if (type == 'Polyline' && coords?.[0] != coords?.[coords?.length - 1]) { coords?.push(coords?.[0]) }
    const paths = coords ? { path: coords } : { paths: coordsWithHoles }
    const objectType = this.#getObjectType({ type, coords })
    const objectsOptions = {
      Polygon: () => { return { position: coords ? coords?.[0] : coordsWithHoles?.[0]?.[0], ...paths, ...options({ type, coords, coordsWithHoles, options }) } },
      Marker: () => { return { position: coords?.[0], ...options({ type, coords, coordsWithHoles, options }) } },
      Circle: () => options({ type, coords, coordsWithHoles, options }),
      Rectangle: () => options({ type, coords, coordsWithHoles, options })
    }
    const insert = (_objectType) => {
      const object = new window.google.maps[_objectType](objectsOptions[_objectType]())
      object.setMap(this.#map)
      let builderRight = {  }
      // builderRight.info = infoRight ? infoRight : this.defaultInfoWindowRight.info
      // builderRight.events = eventsRight ? eventsRight : this.defaultInfoWindowRight.events
      if (info) { object.addListener('click', (event) => { this.mouseClick({ info, events, builderRight, event, object, button: 'left', data }) }) }
      // object.addListener('rightclick', (event) => { this.mouseClick(_builder, builderRight, event, object, 'right') })
      // if (_builder.Map.objectsName) { this.addToMapArray({ builder: _builder, array: 'objects', item: object }) }
      return object
    }
    // if (builder.extraMarker) {
    //   const extraMarkerBuilder = { ...builder }
    //   extraMarkerBuilder.name = `${builder.name}Marker`
    //   extraMarkerBuilder.label = `${builder.label} (Marker)`
    //   insert(extraMarkerBuilder, 'Marker')
    // }
    return insert(objectType)
  }
  insertAll = () => this.#itemsOnMap = this.#poligonsComInfo.map(_polygon => this.insertObject(_polygon))
  saveOnField = (field, items) => field = items ? items : this.#itemsOnMap
  sync = (map) => {
    const maps = [map, this.#map]
    let center = map.getCenter()
    let zoom = map.getZoom()
    this.#map.setCenter(center)
    this.#map.setZoom(zoom)
    const update = (changedMap) => {
      maps.forEach(m => {
        if (m === changedMap) { return }
        m.setCenter(center)
        m.setZoom(zoom)
      })
    }
    maps.forEach(m => {
      m.addListener("bounds_changed", () => {
        const changedCenter = m.getCenter()
        const changedZoom = m.getZoom()
        if (changedCenter !== center || changedZoom !== zoom) {
          center = changedCenter
          zoom = changedZoom
          update(m)
        }
      })
    })
  }
  mouseClick = function({ info, events, builderRight, event, object, button, data }) {
    console.log({ builderRight, button })
    // const _button = button == 'left' ? 'right' : 'left'
    // button = (builderRight.Map.$refs.ToolBar && builderRight.Map.$refs.ToolBar.mouseReverse.active) ? _button : button
    // if (builder.Map?.field?.clickRightObject && builder.Map?.field?.mouseReverse) { button = builder.Map?.field?.mouseReverse ? _button : button }
    // if (button == 'right') { this.rightClick(builderRight, event, object) }
    // else { this.openInfoWindow({ info, events, event, object }) }
    this.openInfoWindow({ info, events, event, object, data })
  }
  openInfoWindow = async function({ info, events, event, object, data }) {
    let infoWindow = new window.google.maps.InfoWindow()
    infoWindow.setContent(await info(object))
    !events || infoWindow.addListener("domready", () => {
      events.map(listener => {
        if (document.querySelector(listener.obj)) {
          const tags = document.querySelectorAll(listener.obj)
          tags.forEach((tag) => { tag[listener.event] = () => { listener.action({ infoWindow, object, tag, data }) } })
        }
      })
    })
    infoWindow.setPosition(event.latLng)
    infoWindow.open(this.#map)
    return infoWindow
  }
}